import React, { useState } from "react";
import axios from "axios";
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from "@heroicons/react/20/solid";
import { Squares2X2Icon, CubeIcon, CalendarIcon, ChartBarIcon, AdjustmentsHorizontalIcon, RectangleGroupIcon, ArrowLeftStartOnRectangleIcon } from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";
import Rebin from "../assets/embleem wit@4x.png";
import { Tooltip } from "@material-tailwind/react";

const Navbar = ({ setIsAuthenticated, userInfo, userId }) => {
  const [expanded, setExpanded] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
  };

  const handleStatistics = async () => {
    await axios.put(`/usage/updateGlobalStatisticsCount/${userId}`);
  }

  return (
    <section className='h-screen fixed sticky inset-y-0 z-10'>
      <nav className="h-full flex flex-col justify-between bg-white border-r ">
        <div className={`p-4 pb-4 flex items-center ${expanded ? "justify-between" : "justify-center"}`}>
          <div className={`overflow-hidden transition-all ${expanded ? "flex gap-x-5 items-center" : "hidden"}`}>
            <img className="bg-rebin-blue w-12 p-3 shadow-md rounded-lg" src={Rebin} alt="Rebin" />
            <span className="text-xl font-medium">Rebin</span>
          </div>
          <button onClick={() => setExpanded(curr => !curr)} className={`rounded-lg transition-all ${expanded ? "ml-auto" : ""}`}>
            {expanded ? <ChevronDoubleLeftIcon className="h-6 w-auto" /> : <ChevronDoubleRightIcon className="mt-2 h-6 w-auto" />}
          </button>
        </div>

        {userInfo?.user_business_role === 'Planner' ? (
          <ul className="flex-1 px-3 mt-5">
            <Tooltip content={!expanded ? "Dashboard" : ""} placement="right" className={`text-black border border-blue-gray-50 bg-white px-2 py-2 shadow-xl shadow-black/10 ${expanded ? "display-hidden" : "" }`}>
              <NavLink to="/application" end className={({ isActive }) => `relative flex items-center py-2 px-3 my-1 rounded-md transition-colors ${isActive ? "bg-rebin-light-blue text-rebin-blue" : "bg-rebin-light-blue-hover text-rebin-blue-hover text-black"} ${expanded ? "" : "px-0 justify-center"}`}>
                <Squares2X2Icon className="w-5 font-light " />
                <span className={`overflow-hidden transition-all font-light ${expanded ? "w-52 ml-3" : "w-0"}`}>Dashboard</span>
              </NavLink>
            </Tooltip>
            <Tooltip content={!expanded ? "Planning" : ""} placement="right" className="text-black border border-blue-gray-50 bg-white px-2 py-2 shadow-xl shadow-black/10">
              <NavLink to="/application/planning" className={({ isActive }) => `relative flex items-center py-2 px-3 my-1 rounded-md transition-colors ${isActive ? "bg-rebin-light-blue text-rebin-blue" : "bg-rebin-light-blue-hover text-rebin-blue-hover text-black"} ${expanded ? "" : "px-0 justify-center"}`}>
                <CalendarIcon className="w-5 font-light " />
                <span className={`overflow-hidden transition-all font-light ${expanded ? "w-52 ml-3" : "w-0"}`}>Planning</span>
              </NavLink>
            </Tooltip>
            <Tooltip content={!expanded ? "Overview" : ""} placement="right" className="text-black border border-blue-gray-50 bg-white px-2 py-2 shadow-xl shadow-black/10">
              <NavLink to="/application/overview" className={({ isActive }) => `relative flex items-center py-2 px-3 my-1 rounded-md transition-colors ${isActive ? "bg-rebin-light-blue text-rebin-blue" : "bg-rebin-light-blue-hover text-rebin-blue-hover text-black"} ${expanded ? "" : "px-0 justify-center"}`}>
                <CubeIcon className="w-5 font-light" />
                <span className={`overflow-hidden transition-all font-light ${expanded ? "w-52 ml-3" : "w-0"}`}>Overview</span>
              </NavLink>
            </Tooltip>
            <Tooltip content={!expanded ? "Regions" : ""} placement="right" className="text-black border border-blue-gray-50 bg-white px-2 py-2 shadow-xl shadow-black/10">
              <NavLink to="/application/regions" className={({ isActive }) => `relative flex items-center py-2 px-3 my-1 rounded-md transition-colors ${isActive ? "bg-rebin-light-blue text-rebin-blue" : "bg-rebin-light-blue-hover text-rebin-blue-hover text-black"} ${expanded ? "" : "px-0 justify-center"}`}>
                <RectangleGroupIcon className="w-5 font-light" />
                <span className={`overflow-hidden transition-all font-light ${expanded ? "w-52 ml-3" : "w-0"}`}>Regions</span>
              </NavLink>
            </Tooltip>
            <Tooltip content={!expanded ? "Statistics" : ""} placement="right" className="text-black border border-blue-gray-50 bg-white px-2 py-2 shadow-xl shadow-black/10">
              <NavLink to="/application/statistics" onClick={handleStatistics} className={({ isActive }) => `relative flex items-center py-2 px-3 my-1 rounded-md transition-colors ${isActive ? "bg-rebin-light-blue text-rebin-blue" : "bg-rebin-light-blue-hover text-rebin-blue-hover text-black"} ${expanded ? "" : "px-0 justify-center"}`}>
                <ChartBarIcon className="w-5 font-light " />
                <span className={`overflow-hidden transition-all font-light ${expanded ? "w-52 ml-3" : "w-0"}`}>Statistics</span>
              </NavLink>
            </Tooltip>
            <Tooltip content={!expanded ? "Settings" : ""} placement="right" className="text-black border border-blue-gray-50 bg-white px-2 py-2 shadow-xl shadow-black/10">
              <NavLink to="/application/settings" className={({ isActive }) => `relative flex items-center py-2 px-3 my-1 rounded-md transition-colors ${isActive ? "bg-rebin-light-blue text-rebin-blue" : "bg-rebin-light-blue-hover text-rebin-blue-hover text-black"} ${expanded ? "" : "px-0 justify-center"}`}>
                <AdjustmentsHorizontalIcon className="w-5 font-light " />
                <span className={`overflow-hidden transition-all font-light ${expanded ? "w-52 ml-3" : "w-0"}`}>Settings</span>
              </NavLink>
            </Tooltip>
          </ul>
        ) : null}

        <div className={`border-t flex p-3 items-center ${expanded ? "" : "justify-center"}`}>
          <div className="w-10 h-10 rounded-md bg-rebin-light-blue text-rebin-blue flex items-center justify-center text-sm font-bold">
            <span>{`${userInfo.user_name[0]}${userInfo.user_name[userInfo.user_name.indexOf(" ") + 1] || ""}`}</span>
          </div>
          <div className={`flex justify-between items-center overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0"}`}>
            <div className="leading-4">
              <h4 className="font-medium text-sm">{userInfo.user_name}</h4>
              <span className="text-xs text-rebin-grey">{userInfo.user_email}</span>
            </div>
          </div>
          {expanded && (
            <button onClick={handleLogout} className="text-rebin-blue-hover">
              <ArrowLeftStartOnRectangleIcon className="w-5" />
            </button>
          )}
        </div>
      </nav>
    </section>
  );
};

export default Navbar;








