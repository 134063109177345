import React, { Suspense, lazy, useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom'; // Assuming you're using react-router-dom
import { getCustomerId, getUserId } from '../../api/DashboardApi';
import { getUserInfo } from '../../api/SettingsApi';
import { getAssets } from '../../api/AssetsApi';
import { getClusters } from '../../api/ClustersApi';
import Navbar from '../../components/Navbar';
import SuccessDialog from '../../components/dialogs/SuccessDialog';

// Lazy load your components
const Dashboard = lazy(() => import('../dashboard/Dashboard'));
const Planning = lazy(() => import('../planning/Planning'));
const Overview = lazy(() => import('../overview/Overview'));
const Regions = lazy(() => import('../regions/Regions'));
const Settings = lazy(() => import('../settings/Settings'));
const Statistics = lazy(() => import('../statistics/Statistics'));
const Driver = lazy(() => import('../driver/Driver'));


const Application = ({ setIsAuthenticated }) => {
  const [customerId, setCustomerId] = useState('');
  const [userId, setUserId] = useState('');
  const [assets, setAssets] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [showSuccessDialog, setShowSuccessDialog] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const [userId, customerId] = await Promise.all([getUserId(), getCustomerId()]);
        setUserId(userId);
        const userInfo = await getUserInfo(userId);
        setUserInfo(userInfo);
        setCustomerId(customerId);
        const assets = await getAssets(customerId);
        setAssets(assets);
        const clusters = await getClusters(customerId);
        setClusters(clusters);
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };

    fetchUserData();

    const timer = setTimeout(() => {
      setShowSuccessDialog(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className='flex bg-rebin-light-blue'>
    {userInfo && (
      <>
        <Navbar setIsAuthenticated={setIsAuthenticated} userInfo={userInfo} userId={userId}/>
        <Suspense fallback={<div>Loading...</div>}>
          {customerId && userInfo ? (
            userInfo.user_business_role === 'Planner' ? (
              <Routes>
                <Route path="/" element={<Dashboard customerId={customerId} assets={assets} userInfo={userInfo}/>} />
                <Route path="/planning" element={<Planning assets={assets} customerId={customerId} userId={userId}/>} />
                <Route path="/overview" element={<Overview customerId={customerId} userId={userId} clusters={clusters} assets={assets}/>} />
                <Route path="/regions" element={<Regions customerId={customerId} assets={assets} />} />
                <Route path="/statistics" element={<Statistics customerId={customerId} userId={userId}/>} />
                <Route path="/settings" element={<Settings customerId={customerId} userId={userId} />} />
              </Routes>
            ) : (
              <Routes>
                <Route path="/" element={<Driver customerId={customerId} userId={userId} />} />
              </Routes>
            )
          ) : (
            <div>Loading...</div>
          )}
        </Suspense>
      </>
    )}
    {showSuccessDialog && <SuccessDialog title={'Successful login.'} description={'Welcome back.'} />}
  </div>
  );  
};

export default Application;




