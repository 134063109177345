import axios from 'axios';

export async function getClusters(customerId){
  try {

    const response = await axios.get(`/clusters/getClusters/${customerId}`);
    return (response.data);
    
  } catch (err) {
    console.log(err);
  }
}

export async function getClusterAssets(clusterId){
  try {

    const response = await axios.get(`/clusters/getClusterAssets/${clusterId}`);
    return (response.data);
    
  } catch (err) {
    console.log(err);
  }
}