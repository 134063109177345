import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import FailDialog from '../../components/dialogs/FailDialog';
import Rebin from "../../assets/logo@4x.png";
import Google from "../../assets/google-icon.svg";
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid'; // Importing Heroicons

const Login = ({ setIsAuthenticated }) => {
  const [inputs, setInputs] = useState({
    email: '',
    password: ''
  });

  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false); // State to toggle password visibility

  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post('/auth/login', inputs);
      localStorage.setItem('token', response.data.token);
      setIsAuthenticated(true);
    } catch (err) {
      setError('Invalid email or password. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className='h-screen w-full flex flex-col items-center justify-center bg-rebin-light-blue gap-y-6'>
      <img className='w-20' src={Rebin} alt="Rebin" />
      <div className='mx-auto max-w-md w-full p-6 bg-white rounded-lg shadow-lg'>
        <div className='flex flex-col pb-7 gap-y-5 text-center items-center'>
          <h1 className='text-xl font-semibold text-gray-900'>Log in with your work email</h1>
          <h3 className='text-sm font-light text-gray-600'>
            Use your work email to log in to your team workspace.
          </h3>
          <button className="mt-2 flex w-full items-center justify-center gap-2 px-4 py-2 border border-rebin rounded-md text-sm text-black font-medium">
            <img className='w-4' src={Google} alt="Rebin" />
            <span>Log in with google</span>
          </button>
        </div>
        <div className="flex items-center mb-4">
          <hr className="flex-grow border-gray-200" />
          <span className="px-4 text-sm font-medium text-gray-400">OR</span>
          <hr className="flex-grow border-gray-200" />
        </div>

        <form onSubmit={handleSubmit}>
          <label htmlFor='email' className="text-sm font-medium text-black">
            Email address
          </label>
          <input
            type='email'
            name='email'
            autoComplete='off'
            className='mt-2 block w-full bg-gray-50 rounded-md py-2 pl-5 pr-20 mb-8 text-sm opacity-80 font-light border-rebin focus:ring-2 focus:ring-blue-500 transition'
            placeholder='Email address'
            value={inputs.email}
            onChange={handleChange}
            required
          />
          <div className='flex justify-between'>
            <label htmlFor='password' className="text-sm font-medium text-black">
              Password
            </label>
            <Link
              to='/password'
              className='text-sm font-light text-rebin-blue text-rebin-blue-hover'
            >
              Forgot password?
            </Link>
          </div>
          <div className='relative'>
            <input
              type={passwordVisible ? 'text' : 'password'} // Toggle password visibility
              name='password'
              autoComplete='off'
              placeholder='Enter your password'
              className='mt-2 block w-full bg-gray-50 rounded-md py-2 pl-5 pr-12 mb-8 text-sm opacity-80 font-light border-rebin focus:ring-2 focus:ring-blue-500 transition'
              value={inputs.password}
              onChange={handleChange}
              required
            />
            {/* Eye Icon to toggle password visibility */}
            <button
              type='button'
              className='absolute right-3 top-1/2 transform -translate-y-1/2'
              onClick={togglePasswordVisibility}
            >
              {passwordVisible ? (
                <EyeSlashIcon className='h-3 w-3 text-gray-600' />
              ) : (
                <EyeIcon className='h-4 w-4 text-gray-600' />
              )}
            </button>
          </div>

          <button
            type='submit'
            className={`block rounded-md px-3 py-3 w-full text-sm bg-rebin-blue text-white bg-rebin-hover font-medium ${
              isLoading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            disabled={isLoading}
          >
            {isLoading ? 'Logging in...' : 'Log in'}
          </button>
        </form>
      </div>
      <span className='block text-center text-base font-light text-gray-600 '>
        Don't have an account?{' '}
        <Link to='/register' className='text-rebin-blue text-rebin-blue-hover transition'>
          Create an account
        </Link>
      </span>
      {error && (
        <FailDialog
          error={true}
          setError={setError}
          title='Invalid login.'
          description={error}
        />
      )}
    </div>
  );
};

export default Login;



